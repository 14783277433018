<template>
  <div>
    <img
      src="@/assets/auth-bg.png"
      width="100%"
      alt="授权图"
      v-tracking="{ userVisit: '20' }"
    />
  </div>
</template>

<script>
import {
  getRedirectUrl,
  getUserInfo,
  getLocationParams,
  getLocation,
  checkProjectConfig,
  codeCheck
} from '@/api/index.js'
// import wx from 'weixin-jsapi'
const wx = require('weixin-js-sdk')
import setting from "../setting"

export default {
  name: 'authorize',
  data() {
    return {
      positioning: true, //是否定位
      projectCode: '', //项目码
      qrCode: '', //二维码信息
      code: this.$route.query.code, //微信返回code
      setting
    }
  },
  methods: {
    // 获取用户信息
    getInfo() {
      if (this.code) {
        //调用后端接口
        getUserInfo({ code: this.code }).then(({ data }) => {
          sessionStorage.setItem('openId', data.openId)
          this.getLocation()
        })
      } else {
        getRedirectUrl({
          projectCode: this.projectCode,
          qrCode: this.qrCode
        }).then(({ data }) => {
          let appId = data.appID
          let redirectUri = encodeURIComponent(
            window.location.href.split('#')[0]
          )
          let scope = data.scope
          let httpUrl = data.oauthCodePrefix
          let state = this.projectCode + '/' + this.qrCode
          location.href =
            httpUrl +
            `appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect `
        })
      }
    },
    getLocation() {
      if (this.positioning) {
        // 请求地理位置参数
        getLocationParams({ projectCode: this.projectCode }).then(
          ({ data }) => {
            let { timestamp, appId, nonceStr, signature } = data
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: appId, // 必填，公众号的唯一标识
              timestamp: timestamp, // 必填，生成签名的时间戳
              nonceStr: nonceStr, // 必填，生成签名的随机串
              signature: signature, // 必填，签名，见附录1
              jsApiList: ['getLocation'],// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              openTagList: ['wx-open-launch-weapp'] //可选，需要使用的开放标签列表
            })
            wx.ready(() => {
              wx.getLocation({
                debug: true,
                type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: res => {
                  getLocation({
                    lat: res.latitude,
                    lng: res.longitude
                  }).then(() => {
                    this.turntable()
                  })
                },
                cancel: () => {
                  console.log('取消 getLocation')
                  this.turntable()
                },
                fail: () => {
                  console.log('失败 getLocation')
                  this.turntable()
                }
              })
            })
          }
        )
      } else {
        this.turntable()
      }
    },
    // 跳转转盘页面
    turntable() {
      /* const newUrl = '/' + process.env.VUE_APP_ROUTER_BASE + this.$route.path
      window.history.replaceState('', '', newUrl) */
      this.$router.push({ path: '/turntable' })
    },
    // 项目校验
    getCheckProjectConfig() {
      checkProjectConfig({ projectCode: this.projectCode }).then(({ data }) => {
        this.positioning = data.location
        if (data.status == 1) {
          this.getCodeCheck()
        } else if (data.status == 2 || data.status == 0) {
          //0未开始 2已结束
          this.$router.replace({
            name: 'ActivityStatus',
            query: {
              status: data.status
            }
          })
        }
      })
    },
    // 码校验
    getCodeCheck() {
      codeCheck({ qrCode: this.qrCode }).then(({ data }) => {
        if (data.status == 10) {
          this.getInfo()
        } else {
         data._title = setting.title;
          let queryString = "";
          for (var key in data) {
            queryString += `${key}=${encodeURI(data[key])}&`
          }
          queryString = queryString.substring(0, queryString.lastIndexOf('&'));
          console.log(queryString)

          if (data.verifyPageType == 1) {
            window.location.href = `${process.env.VUE_APP_QCODESTATE_URL}?${queryString}`

          } else if (data.verifyPageType == 0) {
            window.location.href = `${process.env.VUE_APP_CODESTATE_URL}?${queryString}`
          }
        }
      })
    }
  },
  mounted() {
    this.projectCode = this.$route.params.projectCode
    this.qrCode = this.$route.params.qrCode
    sessionStorage.setItem('projectCode', this.projectCode)
    sessionStorage.setItem('qrCode', this.qrCode)
    if (this.code) {
      this.getInfo()
    } else {
      this.getCheckProjectConfig()
      // 进入页面埋点
      this.$sendTracking({
        projectCode: this.projectCode,
        qrCode: this.qrCode,
        userVisit: 13
      })
    }
  }
}
</script>
